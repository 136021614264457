<template>
    <div>
        <div class="course_back">
            <img :src="source.back_img" alt=""/>
        </div>
        <div class="course_mask"></div>
        <div class="course_header">
            <p>{{source.title}}</p>
            <p>{{source.subtitle}}</p>
        </div>
        <div style="background: #fff;border-radius: 20px;margin-bottom: 50px;">
            <div style="height: 20px;"></div>
            <van-cell>
                <template slot="title">
                    <span style="font-size: 20px;font-weight: bold;">课程大纲</span>
                    <img src="../../assets/img/clip.png" style="height: 14px;margin-left: 5px;" alt="">
                </template>
            </van-cell>
            <div class="main_point">
                <p v-for="item in contents"><span>{{item.title}}</span>{{item.subtitle}}</p>
            </div>

            <van-cell>
                <template slot="title">
                    <span style="font-size: 20px;font-weight: bold;">大咖讲师</span>
                    <img src="../../assets/img/clip.png" style="height: 14px;margin-left: 5px;" alt="">
                </template>
            </van-cell>
            <div class="teacher">
                <div>
                    <div>
                        <p>小菲</p>
                        <p>来飞网金牌讲师</p>
                    </div>
                    <div>
                        <img src="../../assets/img/course/teacher.png" alt=""/>
                    </div>
                </div>
                <div>
                    <ul>
                        <li>
                            <span></span>
                            <p>曾先后就职于百度外卖、美团点评等互联网巨头</p>
                        </li>
                        <li>
                            <span></span>
                            <p>头条号专栏作家，多篇关于内容营销的文章被疯转，总浏览量达2000万</p>
                        </li>
                        <li>
                            <span></span>
                            <p>现任来飞网首席讲师，曾为绿茶、小蓝杯、喜茶、外婆家等多家大品牌餐饮企业做市场营销咨询</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div style="background: #fff;width: 100%;height: 30px;"></div>

        </div>
        <van-submit-bar
                :price="0"
                button-text="进入学习"
                text-align="left"
                button-type="warning"
                label="  "
                @submit="onSubmit"
                class="to_learn"
        >
        </van-submit-bar>
    </div>
</template>

<script>
    import {Toast,Dialog} from 'vant';
    import {getQueryVariable} from "../../utils/public";
    export default {
        name: "CourseDetail",
        data(){
            return{
                course_id: 0,
                source:{},
                contents:[],
            }
        },
        mounted() {
            if(this.$route.query.course_id){
                this.course_id = this.$route.query.course_id;
            } else {
                this.course_id = getQueryVariable('course_id');
            }
            if(!this.course_id){
                Dialog({message:'未找到课程'}).then(()=>{ this.$router.replace('/')});
                return;
            }
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            this.loadData();
        },
        methods:{
            loadData(){
                let that = this;
                this.post('/api/client/course/detail',{course_id:this.course_id}).then(result=> {
                    if(result.code === 0){
                        this.source = result.data.source;
                        this.contents = result.data.contents;
                    } else {
                        Dialog({ message: result.msg });
                        that.$router.replace('/');
                    }
                }).catch(e=>{
                    console.log(e);
                    Dialog({ message: '课程详情加载失败' });
                    that.$router.replace('/');
                });
            },
            onSubmit(){
                let course_id = this.course_id;
                this.$router.push({ path: '/lesson', query: { course_id: course_id }});
            },
        }
    }
</script>

<style scoped>

</style>